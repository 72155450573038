import React from 'react';
// pages
import { Home } from "./pages/Home/Home";
function App() {
  return (
    <>
      <Home />
    </>
  );
}

export default App;
